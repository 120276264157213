import React from 'react';
import styled from 'styled-components';

const MarkdownParagraph = styled.p`
  line-height: 2em;
  color: ${props => props.theme.colors.headingSecondary};
  &:first-child {
    margin-top: 0em;
  }
`;

export default {
  paragraph: props => <MarkdownParagraph {...props} />,
};
