import React, { Fragment } from 'react';
import { Box, Image, Flex } from 'rebass/styled-components';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';
import ButtonNav from '../components/ButtonNav';
import Section from '../components/Section';
import Banner from '../components/Banner';
import markdownRenderer from '../components/MarkdownRenderer';

const About = () => (
  <StaticQuery
    query={graphql`
        query AboutMeQuery {
          contentfulAbout {
            aboutMe {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            banner {
              file {
                url
              }
            }
            profile {
              title
              image: resize(width: 450, quality: 100) {
                src
              }
            }
            myProcessImage {
              file {
                url
              }
              description
            }
            name    
          }
        }
      `}
    render={data => {
        const { aboutMe,banner, profile, myProcessImage, name } = data.contentfulAbout;
        return (
          <Fragment>
            <Banner src={banner.file.url} title={name} condensed variant />
            <Section.Container id="about">
              <Flex justifyContent="center" alignItems="flex-start" flexWrap="wrap">
                <Box width={[1, 1, 4 / 6]} px={[1, 2, 4]} mb={[null,32]}>
                  <Fade>
                    <ReactMarkdown
                      source={aboutMe.childMarkdownRemark.rawMarkdownBody}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>
                <Box
                  width={[1, 1, 2 / 6]}
                  style={{ maxWidth: '300px', margin: 'auto' }}
                  pb={[5,6]}
                >
                  <Fade right>
                    <Image
                      src={profile.image.src}
                      alt={profile.title}
                      mt={[4, 4, 0]}
                      ml={[0, 0, 1]}
                    />
                  </Fade>
                </Box>
                <Box width={1} mb={[4]}>
                  <Fade>
                    <Image display={['none','block']} src={myProcessImage.file.url} alt={myProcessImage.description} />
                  </Fade>
                </Box>
                <ButtonNav 
                  link="projects"
                  text="View My Projects"
                /> 
              </Flex>
            </Section.Container>
          </Fragment>
        );
      }}
  />
);

export default About;

